/* eslint-disable no-undef */
import { Edit } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PopoverAG from "components/ui/popoverAG";
import useFormatter from "hooks/useFormatter";
import useQueries from "hooks/useQueries";
import { setLoading } from "lib/Reducer/loadingSlice";
import moment from "moment";
import { BsChevronExpand } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateDueDate } from "../../api/commissions";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  setReload,
  handleTableCheckBox,
  selectedRows,
  permissions,
  openModal,
  setOpenModal,
  symbol,
  setOpenNote,
  openNote,
}) => {
  const { setQueries } = useQueries();
  const dispatch = useDispatch();
  let { formatDate } = useFormatter();

  const handleDueDate = async (due_date) => {
    dispatch(setLoading(true));
    const body_data = {
      id: row?.id,
      due_date,
    };
    try {
      const result = await updateDueDate({ body_data });
      if (result.success) {
        toast.success(result.message);
        setReload((pre) => !pre);
      } else {
        toast.error(result.message);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <TableRow
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 16px",
        },
        "& .MuiTableCell-sizeMedium:first-of-type": {
          paddingLeft: "8px",
          paddingRight: "0px",
        },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows?.includes(row.id)}
          onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
          disabled={!permissions["bulk action"]}
        />
      </TableCell>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.format && typeof value === "number" ? (
              column.format(value)
            ) : column.id === "expense_date" ? (
              moment(value).format("ll")
            ) : column.id === "tax" ? (
              <Typography color="#2fb42f" variant="body2">
                {"+ " + row.symbol + value}
              </Typography>
            ) : column.id === "discount" ? (
              <Typography color="#ff3535" variant="body2">
                {"- " + row.symbol + value}
              </Typography>
            ) : column.id === "total" ? (
              // tempvalue = row.total + row.tax - row.discount,
              <Typography color="#7171ff" variant="body2">
                {row.symbol}
                {value + row.tax - row.discount}
              </Typography>
            ) : column.id === "due" || column.id === "payable" || column.id === "paid" ? (
              <Typography variant="body2">
                {row?.symbol} {value || 0}
              </Typography>
            ) : column.id === "subtotal" ? (
              <Typography variant="body2">{row.symbol + value}</Typography>
            ) : column.id === "due_date" || column.id === "student_due_date" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>{formatDate(value)}</span>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Custom input"
                    value={value}
                    onChange={(newValue) => {
                      handleDueDate(moment(newValue).format("YYYY-MM-DD"));
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {InputProps?.endAdornment}
                        <input
                          style={{
                            width: "0px",
                            height: "0px",
                            opacity: 0,
                          }}
                          ref={inputRef}
                          {...inputProps}
                        />
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </Box>
            ) : column.id === "assignees" ? (
              <PopoverAG items={value} />
            ) : column.id === "university_commissions" ? (
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  pointerEvents: !!value?.length ? "all" : "none",
                  "&:hover": {
                    "& svg": {
                      color: (theme) => theme.palette.primary.main,
                    },
                  },
                  "& svg": {
                    position: "absolute",
                    color:
                      openModal?.id === row?.id
                        ? (theme) => theme.palette.primary.main
                        : "#777",
                    top: "0px",
                    right: "0px",
                    cursor: "pointer",
                    fontSize: "17px",
                  },
                }}
                onClick={() => {
                  setOpenModal(
                    openModal?.id === row?.id
                      ? {}
                      : { type: "university", id: row?.id }
                  );
                }}
              >
                <Typography
                  sx={{
                    overflow: "hidden",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                    maxHeight: "22px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {!!value?.length
                    ? row?.currency_symbol +
                      value?.reduce(
                        (acc, curr) => acc + curr?.university_commission_amount,
                        0
                      )
                    : "-"}
                </Typography>
                {!!value?.length && <BsChevronExpand />}
              </Box>
            ) : column.id === "last_note" ? (
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover": {
                    "& svg": {
                      color: (theme) => theme.palette.primary.main,
                    },
                  },
                  "& svg": {
                    position: "absolute",
                    color:
                      openNote === row?.id
                        ? (theme) => theme.palette.primary.main
                        : "#777",
                    top: "0px",
                    right: "0px",
                    cursor: "pointer",
                    fontSize: "17px",
                  },
                }}
                onClick={() => setOpenNote(openNote === row?.id ? "" : row?.id)}
              >
                <Typography
                  sx={{
                    overflow: "hidden",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                    maxHeight: "22px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {value || "-"}
                </Typography>
                <MdEdit />
              </Box>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Details">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=1`)}>
                    <TbListDetails />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setQueries(`id=${row?.id}&m=0`)}
                    disabled={!permissions.update}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    disabled={!permissions.trash}
                  >
                    <MdDelete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value 
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
