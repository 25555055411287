import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import { createLead, updateLead } from "features/leads/api/leads";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useQueries from "hooks/useQueries";
import Axios from "lib/Axios";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import handleError from "utils/handleError";
import * as yup from "yup";
import ELPCForms from "./ELPCForms";
import EducationForms from "./EducationForms";
import JobFroms from "./JobFroms";

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  leadStatuses,
  sources,
  assignees,
  countries,
  universities,
  courses,
  institutes,
  subjects,
  allLeads,
  setAllLeads,
}) => {
  const { row_id } = useQueries();
  const { loading, fetchData } = useApi();
  const { user, role } = useSelector((state) => state?.auth?.user_details);
  const [currLead, setCurrLead] = useState(row_id ? {} : persistedData);

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullLead = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/lead/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrLead(result?.data);
    };
    row_id && getFullLead();
  }, []);

  const onSubmit = async (data) => {
    const result = await fetchData(
      row_id ? updateLead(row_id, data) : createLead(data)
    );
    if (result?.success) {
      if (row_id) {
        setAllLeads(
          allLeads.map((lead) =>
            lead.id === currLead.id ? { ...currLead, ...result?.data } : lead
          )
        );
      } else {
        if (
          role === "Admin" ||
          result?.data.lead_assignees.find((a) => a.user_id === user.id)
        ) {
          setAllLeads([{ ...result?.data, lead_notes: [] }, ...allLeads]);
        }
      }
      handleClose("reset");
    } else {
      handleError(result, formik);
    }
  };

  const filterCourses = (courses) => {
    return courses?.map((course) =>
      (({
        course_id,
        overall,
        listening,
        reading,
        writing,
        speaking,
        literacy,
        comprehension,
        conversation,
        production,
      }) => ({
        course_id,
        overall,
        listening,
        reading,
        writing,
        speaking,
        literacy,
        comprehension,
        conversation,
        production,
      }))(course)
    );
  };

  const filterJobs = (jobs) => {
    return jobs?.map((job) =>
      (({
        company_address,
        company_name,
        duration,
        end_date,
        join_date,
        title,
      }) => ({
        company_address,
        company_name,
        duration,
        end_date,
        join_date,
        title,
      }))(job)
    );
  };

  const initialValues = {
    name: currLead?.name || null,
    phone: currLead?.phone || null,
    additional_phone: currLead?.additional_phone || null,
    email: currLead?.email || null,
    lead_status_id: currLead?.lead_status_id || null,
    source_id: currLead?.source_id || null,
    assignees: currLead?.assignees ||
      currLead?.lead_assignees?.map((assignee) => assignee?.user_id) || [
        user?.id,
      ],
    preferred_countries:
      currLead?.preferred_countries ||
      currLead?.lead_preferred_countries?.map((country) =>
        Number(country?.country_id)
      ) ||
      [],
    country_id: currLead?.country_id || null,
    description: currLead?.description || null,
    address: currLead?.address || null,
    city: currLead?.city || null,
    state: currLead?.state || null,
    contacted_date: currLead?.contacted_date || null,
    // Educations
    educations: currLead?.educations || [],
    // SSC
    ssc_group: currLead.ssc_group || currLead?.lead_s_s_c?.group || null,
    ssc_gpa: currLead.ssc_gpa || currLead?.lead_s_s_c?.gpa || null,
    ssc_passing_year:
      currLead.ssc_passing_year || currLead?.lead_s_s_c?.passing_year || null,
    ssc_board: currLead.ssc_board || currLead?.lead_s_s_c?.board || null,
    // HSC
    hsc_group: currLead.hsc_group || currLead?.lead_h_s_c?.group || null,
    hsc_gpa: currLead.hsc_gpa || currLead?.lead_h_s_c?.gpa || null,
    hsc_passing_year:
      currLead.hsc_passing_year || currLead?.lead_h_s_c?.passing_year || null,
    hsc_board: currLead.hsc_board || currLead?.lead_h_s_c?.board || null,
    // Bachelor
    bachelor_subject_id:
      currLead.bachelor_subject_id ||
      currLead?.lead_bachelor?.subject_id ||
      null,
    bachelor_gpa: currLead.bachelor_gpa || currLead?.lead_bachelor?.gpa || null,
    bachelor_passing_year:
      currLead.bachelor_passing_year ||
      currLead?.lead_bachelor?.passing_year ||
      null,
    bachelor_university_id:
      currLead.bachelor_university_id ||
      currLead?.lead_bachelor?.university_id ||
      null,
    // Master
    master_subject_id:
      currLead.master_subject_id || currLead?.lead_master?.subject_id || null,
    master_gpa: currLead.master_gpa || currLead?.lead_master?.gpa || null,
    master_passing_year:
      currLead.master_passing_year ||
      currLead?.lead_master?.passing_year ||
      null,
    master_university_id:
      currLead.master_university_id ||
      currLead?.lead_master?.university_id ||
      null,

    // Diploma
    diploma_subject_id:
      currLead?.diploma_subject_id ||
      currLead?.lead_diploma?.subject_id ||
      null,
    diploma_group:
      currLead?.diploma_group || currLead?.lead_diploma?.group || null,
    diploma_gpa: currLead?.diploma_gpa || currLead?.lead_diploma?.gpa || null,
    diploma_passing_year:
      currLead?.diploma_passing_year ||
      currLead?.lead_diploma?.passing_year ||
      null,
    diploma_institute_id:
      currLead?.diploma_institute_id ||
      currLead?.lead_diploma?.institute_id ||
      null,
    diploma_board:
      currLead?.diploma_board || currLead?.lead_diploma?.board || null,

    // O level
    o_level_grade:
      currLead?.o_level_grade || currLead?.lead_o_level?.grade || null,
    o_level_passing_year:
      currLead?.o_level_passing_year ||
      currLead?.lead_o_level?.passing_year ||
      null,
    o_level_institute_id:
      currLead?.o_level_institute_id ||
      currLead?.lead_o_level?.institute_id ||
      null,
    // O level
    a_level_grade:
      currLead?.a_level_grade || currLead?.lead_a_level?.grade || null,
    a_level_passing_year:
      currLead?.a_level_passing_year ||
      currLead?.lead_a_level?.passing_year ||
      null,
    a_level_institute_id:
      currLead?.a_level_institute_id ||
      currLead?.lead_a_level?.institute_id ||
      null,
    //others
    courses: currLead?.courses || filterCourses(currLead?.lead_courses) || [],
    jobs: currLead?.jobs || filterJobs(currLead?.lead_jobs) || [],
  };

  const [preValPhone, setPreValPhone] = useState("");

  const leadAddEditSchema = (preNumber) =>
    yup.object().shape({
      name: yup.string("").required("Name is Required").nullable(),
      lead_status_id: yup.string("").required("Status is Required").nullable(),
      assignees: yup.array().required("Assignee is Required").min(1),
      jobs: yup
        .array()
        .of(
          yup.object().shape({
            company_name: yup.string("").required("Company name is Required"),
            title: yup.string("").required("Job title is Required"),
          })
        )
        .required("Invoice is Required"),
      phone: yup
        .string()
        .required("Phone number is required")
        .test(
          "phone-number-exists",
          "Phone number already exists",
          async function (value) {
            setPreValPhone(this?.originalValue);
            try {
              if (
                value?.length > 9 &&
                value !== preNumber &&
                preValPhone &&
                preValPhone !== this?.originalValue
              ) {
                const response = await Axios.post(
                  "/api/admin/lead/check-phone",
                  {
                    phone: value,
                  }
                );
                if (response.data.code === 200) {
                  return false;
                }
                return true;
              } else {
                return true;
              }
            } catch (error) {
              return true;
            }
          }
        )
        .nullable(),
    });

  const formik = useFormik({
    initialValues,
    // validationSchema: leadAddEditSchema(currLead?.phone),
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: row_id ? "Edit Lead" : "New Lead",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box
          p="20px"
          sx={{
            height: "calc(100vh - 143px)",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "15px",
            }}
          >
            <Select
              options={leadStatuses}
              label="Status *"
              name="lead_status_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <Select
              options={sources}
              label="Source"
              name="source_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <MultiSelect
              name="assignees"
              label="Assigness *"
              formik={formik}
              options={assignees}
              selectProps={{
                id: "user_id",
                label: "name",
              }}
            />
          </Box>
          <Typography
            variant="h5"
            color={(theme) => theme.palette.primary.main}
            borderBottom="1px solid #ddd"
            pb="10px"
            m="35px 0 20px"
          >
            Personal Information
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "15px",
            }}
          >
            <InputField name="name" label="Name *" formik={formik} />
            <InputField name="phone" label="Phone *" formik={formik} />
            <InputField
              name="email"
              label="E-Mail"
              placeholder="example@gmail.com"
              formik={formik}
            />

            <InputField
              name="additional_phone"
              label="Additional Phone"
              formik={formik}
            />

            <Select
              isCountry={true}
              options={countries}
              label="Country"
              name="country_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <InputField name="city" label="City" formik={formik} />
            <InputField
              name="address"
              label="Address"
              placeholder="Sector-10, Uttara"
              formik={formik}
            />
            <DateTimePicker
              name="contacted_date"
              label="Date of contact"
              formik={formik}
            />
            <InputField
              name="description"
              label="Aditional Informations"
              formik={formik}
              multiline
              rows={3}
              sx={{ gridColumn: "1 / -1" }}
            />
          </Box>
          <Typography
            variant="h5"
            color={(theme) => theme.palette.primary.main}
            borderBottom="1px solid #ddd"
            pb="10px"
            m="35px 0 20px"
          >
            Educational Information
          </Typography>
          <EducationForms
            {...{
              formik,
              subjects,
              institutes,
              universities,
            }}
          />

          <Box
            sx={{
              gridColumn: "1 / -1",
              borderBottom: "1px solid #ddd",
              pb: "10px",
              m: "35px 0 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color={(theme) => theme.palette.primary.main}
            >
              English language Proficiency
            </Typography>
            <Button
              startIcon={<Add />}
              onClick={() =>
                formik.setFieldValue("courses", [
                  {
                    course_id: null,
                    overall: null,
                    listening: null,
                    reading: null,
                    writing: null,
                    speaking: null,
                    literacy: null,
                    comprehension: null,
                    conversation: null,
                    production: null,
                  },
                  ...formik.values.courses,
                ])
              }
            >
              {formik.values?.courses?.length
                ? "Add another course"
                : "Add course"}
            </Button>
          </Box>
          {formik.values?.courses?.length > 0 &&
            formik.values?.courses.map((singleCourse, i) => (
              <ELPCForms
                singleCourse={singleCourse}
                formik={formik}
                i={i}
                courses={courses}
              ></ELPCForms>
            ))}

          <Box
            sx={{
              gridColumn: "span 2",
              borderBottom: "1px solid #ddd",
              pb: "10px",
              m: "35px 0 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color={(theme) => theme.palette.primary.main}
            >
              Job Exprience
            </Typography>
            <Button
              startIcon={<Add />}
              onClick={() => {
                formik.setFieldValue("jobs", [
                  ...formik.values.jobs,
                  {
                    company_address: null,
                    company_name: null,
                    duration: null,
                    end_date: null,
                    join_date: null,
                    title: null,
                  },
                ]);
              }}
            >
              {formik.values?.jobs?.length ? "Add another job" : "Add job"}
            </Button>
          </Box>
          {formik.values.jobs?.length > 0 &&
            formik.values?.jobs.map((singleJob, i) => (
              <JobFroms singleJob={singleJob} formik={formik} i={i}></JobFroms>
            ))}
          <Typography
            variant="h5"
            color={(theme) => theme.palette.primary.main}
            borderBottom="1px solid #ddd"
            pb="10px"
            m="35px 0 20px"
          >
            Preferred Countries
          </Typography>
          <MultiSelect
            options={countries}
            label="Select countries"
            value={formik?.values?.preferred_countries}
            onChange={(value) =>
              formik.setFieldValue("preferred_countries", value)
            }
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          {row_id ? "Update Lead" : "Save Lead"}
        </Button>
      </Box>
    </RightDrawer>
  );
};

export default AddEdit;
