import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import { useState } from "react";
import MainTableRow from "./MainTableRow";
import TableModal from "./TableModal";
import { useSelector } from "react-redux";
import Note from "components/common/note";

const MainTable = ({
  setAllReference,
  allReference,
  handleDelete,
  handleEdit,
  handleView,
  setReload,
  pagination,
  setPagination,
  loading,
  handleTableCheckBox,
  selectedRows,
  permissions,
}) => {
  const [openNote, setOpenNote] = useState("");

  const [referenceCommission, setReferenceCommission] = useState(0);
  const [openModal, setOpenModal] = useState({ type: "", id: "" });
  const rows = allReference;
  const columns = [
    { id: "reference_name", label: "Reference" },
    { id: "customer_name", label: "Customer" },
    { id: "project_name", label: "Project" },
    { id: "intake_name", label: "Intake" },
    { id: "university_name", label: "University" },
    { id: "branch_name", label: "Branch" },
    { id: "last_note", label: "Last Note", style: { minWidth: "190px" } },

    { id: "due_date", label: "Due Date" },

    { id: "paid", label: "Total Paid" },
    { id: "payable", label: "Payble Amount" },
    { id: "due", label: "Total Due" },
    { id: "student_due_date", label: "Student Due Date" },
    // { id: "university_commissions", label: "University Commission" },
    {
      id: "action",
      label: "Actions",
      style: { width: "170px" },
      align: "center",
    },
  ];
  const currency_id = useSelector(
    (state) => state?.base?.base_data?.settings?.app?.currency_id
  );

  const currencies = useSelector((state) => state?.base?.base_data?.currencies);

  const symbol = currency_id
    ? currencies?.find((c) => c.id === currency_id)?.symbol
    : "";
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 257px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 16px",
                  },
                  "& .MuiTableCell-sizeMedium:first-of-type": {
                    paddingLeft: "8px",
                    paddingRight: "0px",
                  },
                }}
              >
                <TableCell padding="checkbox" sx={{ bgcolor: "#fff" }}>
                  <Checkbox
                    disabled={!permissions["bulk action"]}
                    checked={
                      selectedRows?.length === allReference?.length &&
                      allReference?.length
                    }
                    onChange={(e) =>
                      handleTableCheckBox("all", e.target.checked)
                    }
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <MainTableRow
                  symbol={symbol}
                  key={row.id}
                  row={row}
                  columns={columns}
                  span={columns?.length}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  handleView={handleView}
                  setReload={setReload}
                  referenceCommission={referenceCommission}
                  setReferenceCommission={setReferenceCommission}
                  handleTableCheckBox={handleTableCheckBox}
                  selectedRows={selectedRows}
                  allReference={allReference}
                  setAllReference={setAllReference}
                  permissions={permissions}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  openNote={openNote}
                  setOpenNote={setOpenNote}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 257px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
      {openModal?.id && (
        <TableModal
          symbol={symbol}
          open={openModal?.id}
          handleClose={() => setOpenModal({})}
          item={allReference.find((ref) => ref.id === openModal?.id)}
          type={openModal?.type}
        />
      )}
      {openNote && (
        <Note
          open={openNote}
          handleClose={() => setOpenNote(false)}
          id={openNote}
          related_id={openNote}
          related_to="uni_commission"
          table_note_key="last_note"
          route="invoice/university-reference"
          allData={allReference}
          setAllData={setAllReference}
        />
      )}
    </Paper>
  );
};

export default MainTable;
