import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import React, { useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import Items from "./Items";
import MainTableRow from "./MainTableRow";

const ExpandableRow = ({ context, item: row, ...restProps }) => {
  return <MainTableRow {...restProps} {...context} key={row.id} row={row} />;
};

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: ExpandableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const MainTable = ({
  setAllInvoices,
  allInvoices,
  handleDelete,
  setReload,
  pagination,
  setPagination,
  loading,
  handleTableCheckBox,
  selectedRows,
  permissions,
}) => {
  const [openItems, setOpenItems] = useState("");
  const rows = allInvoices;

  const columns = [
    { id: "invoice_number", label: "Invoice" },
    { id: "project_name", label: "Project" },
    { id: "customer_name", label: "Customer Name" },
    { id: "due_date", label: "Due Date" },
    { id: "intake_name", label: "Intake" },
    { id: "assignees", label: "Assigned", align: "center" },
    { id: "reference_name", label: "Reference" },
    { id: "created_by_user", label: "Created By", align: "left" },
    {
      id: "invoice_details",
      label: "Items",
      style: { width: "170px" },
    },
    { id: "total", label: "Total", align: "right" },
    { id: "due_payment", label: "Due Amount", align: "right" },
    { id: "paid_amount", label: "Paid Amount", align: "right" },
    { id: "status", label: "Status", align: "center" },
    {
      id: "action",
      label: "Actions",
      style: { width: "170px" },
      align: "center",
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer sx={{ overflowX: "auto" }}>
          <TableVirtuoso
            style={{ height: "calc(100vh - 257px)" }}
            data={rows}
            components={TableComponents}
            context={{
              columns,
              handleDelete,
              openItems,
              setOpenItems,
              handleTableCheckBox,
              selectedRows,
              permissions,
              setReload,
              allInvoices,
              setAllInvoices,
            }}
            fixedHeaderContent={() => (
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 16px",
                  },
                  "& .MuiTableCell-sizeMedium:first-of-type": {
                    paddingLeft: "8px",
                    paddingRight: "0px",
                  },
                }}
              >
                <TableCell padding="checkbox" sx={{ bgcolor: "#fff" }}>
                  <Checkbox
                    disabled={!permissions["bulk action"]}
                    checked={
                      selectedRows?.length === rows?.length && rows?.length
                    }
                    onChange={(e) =>
                      handleTableCheckBox("all", e.target.checked)
                    }
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    sx={{ bgcolor: "#fff" }}
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          />
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 257px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
      {openItems && (
        <Items
          open={openItems}
          handleClose={() => setOpenItems(false)}
          invoice={allInvoices.find((invoice) => invoice.id === openItems)}
        />
      )}
    </Paper>
  );
};

export default MainTable;
