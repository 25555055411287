import Axios from "lib/Axios";

export const viewAllReference = ({ body_data, params }) =>
  Axios.post(
    `/api/admin/invoice/university-reference/list?${params}`,
    body_data
  ).then((res) => res.data);

const endpoints = {
  createReference: (data) => ({
    method: "post",
    url: "/api/admin/invoice/university-reference/create",
    data,
  }),
  updateReference: (id, data) => ({
    method: "put",
    url: `/api/admin/invoice/university-reference/update/${id}`,
    data,
  }),
};

export const { createReference, updateReference } = endpoints;

export const deleteReference = ({ id }) =>
  Axios.delete(`/api/admin/invoice/university-reference/delete/${id}`).then(
    (res) => res.data
  );

export const getAllSemesters = () =>
  Axios.get(`/api/admin/semester/list?`).then((res) => {
    return res.data;
  });

export const getProjects = (id) =>
  Axios.get(`/api/admin/base/project?customer_id=${id}`).then(
    (res) => res.data
  );

export const exportCustomer = ({ body_data }) =>
  Axios.post("/api/admin/customer/export", body_data).then((res) => res.data);

export const importSample = () =>
  Axios.get("/api/admin/lead/sample?type=xlsx").then((res) => res.data);

export const restoreLead = ({ id }) =>
  Axios.get(`/api/admin/lead/restore/${id}`).then((res) => res.data);

export const viewLead = ({ id }) =>
  Axios.get(`/api/admin/lead/show/${id}`).then((res) => res.data);

export const allLeadUtils = () =>
  Axios.get("/api/admin/base/lead-utils").then((res) => res.data);

export const updateReferenceStatus = ({ body_data, id }) =>
  Axios.post(
    `/api/admin/invoice/university-reference/change/status/${id}`,
    body_data
  ).then((res) => res.data);

export const viewNote = ({ id }) =>
  Axios.get(`/api/admin/invoice/university-reference/note/${id}`).then(
    (res) => res.data
  );

export const createNote = ({ body_data }) =>
  Axios.post("/api/admin/note/create", body_data).then((res) => res.data);

export const deleteNote = ({ id }) =>
  Axios.delete(`/api/admin/note/delete/${id}`).then((res) => res.data);

export const viewAttachment = ({ id }) =>
  Axios.get(`api/admin/invoice/university-reference/attachment/${id}`).then(
    (res) => res.data
  );

export const uploadAttachment = ({ body_data, id, setProgress }) =>
  Axios.post(
    `/api/admin/invoice/university-reference/attachment/upload/${id}`,
    body_data,
    {
      onUploadProgress: (progressEvent) => {
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = parseInt(percentComplete * 100);
        setProgress(percentComplete);
      },
    }
  ).then((res) => res.data);

export const deleteAttachment = ({ id }) =>
  Axios.delete(`/api/admin/attachment/delete/${id}`).then((res) => res.data);

export const viewActivity = ({ id }) =>
  Axios.get(`/api/admin/invoice/university-reference/activity/${id}`).then(
    (res) => res.data
  );

export const viewReferenceSummary = () =>
  Axios.post("/api/admin/invoice/university-reference/summery").then(
    (res) => res.data
  );

export const exportReference = ({ body_data }) =>
  Axios.post("/api/admin/invoice/university-reference/export", body_data).then(
    (res) => res.data
  );

export const importLeads = ({ body_data }) =>
  Axios.post("/api/admin/customer/import", body_data).then((res) => res.data);

export const invoiceBulkAction = ({ body_data }) =>
  Axios.post(
    "/api/admin/invoice/university-reference/bulk/action",
    body_data
  ).then((res) => res.data);

export const viewTasks = ({ id, params }) =>
  Axios.post(
    `/api/admin/invoice/university-reference/task/${id}?${params}`
  ).then((res) => res.data);

export const addTask = ({ body_data }) =>
  Axios.post("/api/admin/task/create", body_data).then((res) => res.data);

export const updateTask = ({ id, body_data }) =>
  Axios.post(`/api/admin/task/update/${id}`, body_data).then((res) => res.data);

export const deleteTask = ({ id }) =>
  Axios.delete(`/api/admin/task/delete/${id}`).then((res) => res.data);

export const convertToCustomer = ({ body_data }) =>
  Axios.post("/api/admin/customer/convert/customer", body_data).then(
    (res) => res.data
  );

export const billingShippingAddress = ({ id, body_data }) =>
  Axios.post(`/api/admin/customer/billing-shipping/${id}`, body_data).then(
    (res) => res.data
  );

export const viewAddress = ({ id }) =>
  Axios.get(`/api/admin/base/customer-billing-shipping-address/${id}`).then(
    (res) => res.data
  );

export const showPaymentDetails = ({ body_data }) =>
  Axios.post("/api/admin/payment/list", body_data).then((res) => res.data);

export const projectsList = ({ id }) =>
  Axios.get(`/api/admin/base/project-customer?customer_id=${id}`).then(
    (res) => res.data
  );

export const updateDueDate = ({ body_data }) =>
  Axios.post(
    `/api/admin/invoice/university-reference/duedate-change`,
    body_data
  ).then((res) => res.data);











  const endpointsUniCom = {
    createUniCom: (data) => ({
      method: "post",
      url: "/api/admin/invoice/university-reference/university/create",
      data,
    }),
    updateUniCom: (id, data) => ({
      method: "put",
      url: `/api/admin/invoice/university-reference/university/update/${id}`,
      data,
    }),
  };
  
  export const { createUniCom, updateUniCom } = endpointsUniCom;


  const endpointsStuCom = {
    createStuCom: (data) => ({
      method: "post",
      url: "/api/admin/invoice/university-reference/semester/create",
      data,
    }),
    updateStuCom: (id, data) => ({
      method: "put",
      url: `/api/admin/invoice/university-reference/semester/update/${id}`,
      data,
    }),
  };
  
  export const { createStuCom, updateStuCom } = endpointsStuCom;

  export const deleteUniCom = (id) =>
  Axios.delete(`/api/admin/invoice/university-reference/university/delete/${id}`).then(
    (res) => res.data
  );



  export const deleteStuCom = (id) =>
  Axios.delete(`/api/admin/invoice/university-reference/semester/delete/${id}`).then(
    (res) => res.data
  );






  export const viewUniCom = ({ id }) =>
  Axios.get(`/api/admin/invoice/university-reference/university/list?uni_reference_invoices=${id}`).then(
    (res) => res.data
  );

  export const viewStuCom = ({ id }) =>
  Axios.get(`/api/admin/invoice/university-reference/semester/list?uni_reference_invoices=${id}`).then(
    (res) => res.data
  );


  export const updateComStatus = ({ body_data, id }) =>
  Axios.post(`/api/admin/invoice/university-reference/commission/status/${id}`, body_data).then(
    (res) => res.data
  );
