import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import SelectCustom from "components/ui/select";
import useBase from "hooks/useBase";
import { exam_boards, exam_groups, exam_names } from "utils/constants";

const EducationForms = ({ formik }) => {
  const {
    base: { institutes, universities, facultyWiseSubjects },
  } = useBase();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue(
      "educations",
      typeof value === "string" ? value.split(", ") : value
    );
  };

  const universityOptions = () => {
    return formik.values.country_id
      ? universities?.filter(
          (university) => university?.country_id === formik.values.country_id
        )
      : universities;
  };

  console.log(formik.values, exam_boards);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gap: "15px",
      }}
    >
      <FormControl
        fullWidth
        sx={{
          gridColumn: "1 / -1",
        }}
      >
        <InputLabel id="multiple-checkbox-label">Select Exam types</InputLabel>
        <Select
          size="medium"
          labelId="multiple-checkbox-label"
          multiple
          value={formik?.values?.educations}
          onChange={handleChange}
          input={<OutlinedInput label="Select Exam types" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {exam_names.map((en) => (
            <MenuItem sx={{ p: "2px 5px" }} key={en} value={en}>
              <Checkbox checked={formik?.values?.educations.indexOf(en) > -1} />
              <ListItemText primary={en} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {formik?.values?.educations.includes("SSC") && (
        <>
          <Typography variant="h6" sx={{ gridColumn: "1 / -1" }}>
            SSC / Equivalent
          </Typography>
          <TextField
            size="small"
            label="Select group"
            select
            {...formik.getFieldProps("ssc_group")}
          >
            {exam_groups?.length > 0 ? (
              exam_groups?.map((group) => (
                <MenuItem value={group}>{group}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No group available</MenuItem>
            )}
          </TextField>
          <TextField
            size="small"
            label="GPA"
            placeholder="ex: GPA 5 out of 5"
            {...formik.getFieldProps("ssc_gpa")}
          />
          <DateTimePicker
            name="ssc_passing_year"
            views={["year"]}
            label="Examination Year"
            formik={formik}
          />

          <TextField
            size="small"
            label="Select board"
            select
            {...formik.getFieldProps("ssc_board")}
          >
            {exam_boards?.length ? (
              exam_boards?.map((board) => (
                <MenuItem value={board}>{board}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No board available</MenuItem>
            )}
          </TextField>
        </>
      )}
      {formik?.values?.educations.includes("HSC") && (
        <>
          <Typography variant="h6" sx={{ gridColumn: "1 / -1" }}>
            HSC / Equivalent
          </Typography>
          <TextField
            size="small"
            label="Select group"
            select
            {...formik.getFieldProps("hsc_group")}
          >
            {exam_groups?.length ? (
              exam_groups?.map((group) => (
                <MenuItem value={group}>{group}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No group available</MenuItem>
            )}
          </TextField>
          <TextField
            size="small"
            label="GPA"
            placeholder="ex: GPA 5 out of 5"
            {...formik.getFieldProps("hsc_gpa")}
          />
          <DateTimePicker
            name="hsc_passing_year"
            views={["year"]}
            label="Examination Year"
            formik={formik}
          />
          <TextField
            size="small"
            label="Select board"
            select
            {...formik.getFieldProps("hsc_board")}
          >
            {exam_boards?.length ? (
              exam_boards?.map((board) => (
                <MenuItem value={board}>{board}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No board available</MenuItem>
            )}
          </TextField>
        </>
      )}
      {formik?.values?.educations.includes("Bachelor") && (
        <>
          <Typography variant="h6" sx={{ gridColumn: "1 / -1" }}>
            Bachelor Information
          </Typography>
          <SelectCustom
            isGroup
            options={facultyWiseSubjects}
            label="Select Subject"
            value={formik?.values?.bachelor_subject_id}
            onChange={(value) =>
              formik.setFieldValue("bachelor_subject_id", value)
            }
            selectProps={{ id: "id", label: "name" }}
          />
          <TextField
            size="small"
            label="CGPA"
            placeholder="ex: GPA 5 out of 5"
            {...formik.getFieldProps("bachelor_gpa")}
          />
          <DateTimePicker
            name="bachelor_passing_year"
            views={["year"]}
            label="Examination Year"
            formik={formik}
          />
          <TextField
            size="small"
            label="University Name"
            select
            {...formik.getFieldProps("bachelor_university_id")}
          >
            {universityOptions()?.length ? (
              universityOptions()?.map((university) => (
                <MenuItem value={university.id}>{university.name}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No university available</MenuItem>
            )}
          </TextField>
        </>
      )}

      {formik?.values?.educations.includes("Diploma") && (
        <>
          <Typography variant="h6" sx={{ gridColumn: "1 / -1" }}>
            Diploma Information
          </Typography>

          <SelectCustom
            isGroup
            options={facultyWiseSubjects}
            label="Select Subject"
            value={formik?.values?.diploma_subject_id}
            onChange={(value) =>
              formik.setFieldValue("diploma_subject_id", value)
            }
            selectProps={{ id: "id", label: "name" }}
          />

          <TextField
            size="small"
            label="Select group"
            select
            {...formik.getFieldProps("diploma_group")}
          >
            {exam_groups?.length ? (
              exam_groups?.map((group) => (
                <MenuItem value={group}>{group}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No group available</MenuItem>
            )}
          </TextField>

          <TextField
            size="small"
            label="CGPA"
            placeholder="ex: GPA 5 out of 5"
            {...formik.getFieldProps("diploma_gpa")}
          />
          <DateTimePicker
            name="diploma_passing_year"
            views={["year"]}
            label="Examination Year"
            formik={formik}
          />

          <TextField
            size="small"
            label="Select board"
            select
            {...formik.getFieldProps("diploma_board")}
          >
            {exam_boards?.length ? (
              exam_boards?.map((board) => (
                <MenuItem value={board}>{board}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No board available</MenuItem>
            )}
          </TextField>

          <SelectCustom
            options={institutes}
            label="Select Institute"
            value={formik?.values?.diploma_institute_id}
            onChange={(value) =>
              formik.setFieldValue("diploma_institute_id", value)
            }
            selectProps={{ id: "id", label: "name" }}
          />
        </>
      )}

      {formik?.values?.educations.includes("Masters") && (
        <>
          <Typography variant="h6" sx={{ gridColumn: "1 / -1" }}>
            Master Information
          </Typography>

          <SelectCustom
            isGroup
            options={facultyWiseSubjects}
            label="Select Subject"
            value={formik?.values?.master_subject_id}
            onChange={(value) =>
              formik.setFieldValue("master_subject_id", value)
            }
            selectProps={{ id: "id", label: "name" }}
          />

          <TextField
            size="small"
            label="CGPA"
            placeholder="ex: GPA 5 out of 5"
            {...formik.getFieldProps("master_gpa")}
          />
          <DateTimePicker
            name="master_passing_year"
            views={["year"]}
            label="Examination Year"
            formik={formik}
          />
          <TextField
            // disabled={!formik.values.country_id}
            size="small"
            label="University Name"
            select
            {...formik.getFieldProps("master_university_id")}
          >
            {universityOptions()?.length ? (
              universityOptions()?.map((university) => (
                <MenuItem value={university.id}>{university.name}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No university available</MenuItem>
            )}
          </TextField>
        </>
      )}
      {formik?.values?.educations.includes("O level") && (
        <>
          <Typography variant="h6" sx={{ gridColumn: "1 / -1" }}>
            O level
          </Typography>
          <TextField
            size="small"
            label="Grade"
            placeholder="ex: A"
            {...formik.getFieldProps("o_level_grade")}
          />
          <DateTimePicker
            name="o_level_passing_year"
            views={["year"]}
            label="Examination Year"
            formik={formik}
          />

          <SelectCustom
            options={institutes}
            label="Select Institute"
            value={formik?.values?.o_level_institute_id}
            onChange={(value) =>
              formik.setFieldValue("o_level_institute_id", value)
            }
            selectProps={{ id: "id", label: "name" }}
          />
        </>
      )}
      {formik?.values?.educations.includes("A level") && (
        <>
          <Typography variant="h6" sx={{ gridColumn: "1 / -1" }}>
            A level
          </Typography>
          <TextField
            size="small"
            label="Grade"
            placeholder="ex: A"
            {...formik.getFieldProps("a_level_grade")}
          />
          <DateTimePicker
            name="a_level_passing_year"
            views={["year"]}
            label="Examination Year"
            formik={formik}
          />

          <SelectCustom
            options={institutes}
            label="Select Institute"
            value={formik?.values?.a_level_institute_id}
            onChange={(value) =>
              formik.setFieldValue("a_level_institute_id", value)
            }
            selectProps={{ id: "id", label: "name" }}
          />
        </>
      )}
    </Box>
  );
};

export default EducationForms;
